import React from "react";

const SkillTab = (props) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col p-1 lg:p-8">
        <div className="flex flex-col border-2  rounded-full h-56 w-44 border-[#565656] hover:border-[#28E98C] duration-500">
          <img
            src={props.data.url}
            alt="php"
            className="h-32 w-32 mx-auto mt-3 p-4"
          />
          <span className="text-center text-[#28E98C] text-2xl">
            {props.data.experience}
          </span>
        </div>
        <span className="text-white text-2xl font-light text-center mt-4">
          {props.data.name}
        </span>
      </div>
    </div>
  );
};
export default SkillTab;
