import React from "react";

const SideProfile = ({
  location,
  name,
  links,
  email,
  occupation,
  pictureUrl,
  cv,
}) => {
  return (
    <div className="flex flex-col rounded-3xl border border-[#565656] text-white p-6 h-fit w-full lg:max-w-[26rem] lg:mx-0 max-w-[45rem] mx-auto mt-4">
      <div className="flex flex-row justify-between lg:mx-2">
        <div className="flex flex-col text-start my-auto mr-2">
          <span className="text-3xl lg:text-3xl font-semibold">
            {name.split(" ")[0].toUpperCase()}
          </span>
          <span className="text-2xl lg:text-2xl font-thin">
            {name.split(" ")[1].toUpperCase()}
          </span>
        </div>
        <div className="flex text-end max-w-[8rem] text-sm lg:text-md">
          <span className="my-auto font-light">{occupation}</span>
        </div>
      </div>
      <div className="mx-auto my-6">
        <img
          alt=""
          src={pictureUrl}
          className="rounded-3xl max-w-[20rem] w-full"
        />
      </div>
      <div className="flex flex-col text-xl font-light my-3 text-center lg:text-2xl ">
        <span className="mx-2">{location}</span>
        <span className="mx-2">{email}</span>
      </div>
      <span className="text-center text-sm font-light">
        {"@2023 " + name.split(" ")[0].toUpperCase() + ", All Rights Reserved"}
      </span>
      <div className="flex flex-row mx-auto my-6 justify-between max-w-[20rem] w-full">
        {links.map((link, index) => (
          <div className="border rounded-full w-[3rem] h-[3rem] border-[#565656] text-[#565656] flex text-2xl items-center justify-center">
            <a href={link.url} target="_blank" rel="noopener noreferrer">
              {link.icon}
            </a>
          </div>
        ))}
      </div>
      <a href={cv} download="cv.pdf">
        <div className="select-none max-w-[20rem] w-full h-[3rem] rounded-3xl bg-[#28E98C] border-2 border-[#28E98C] mx-auto mt-2 mb-6 flex justify-center items-center font-light text-xl text-black hover:text-white hover:bg-transparent transition duration-300 ease-in-out">
          <span>DOWNLOAD CV</span>
        </div>
      </a>
    </div>
  );
};
export default SideProfile;
