import React from "react";
import {
  AiFillFacebook,
  AiFillGithub,
  AiFillLinkedin,
  AiOutlineInstagram,
} from "react-icons/ai";

const getData = () => {
  const data = {
    name: "Mozharul Haq",
    email: "mazharul.haq016@gmail.com",
    occupation: "Software Engineer",
    about:
      "Experienced Software Engineer with one year of expertise in API development, unit testing, and query optimization. Proven problem solver and team player passionate about innovation and continuous learning. Open to new opportunities for collaboration and professional growth.",
    introduction:
      "I develop full-stack websites and I enjoy what I do. I strive to be in a constant growth.",
    pictureUrl: "/pic.jpg",
    location: "Dhaka, Bangladesh",
    links: [
      {
        name: "Github",
        url: "https://github.com/matr1x01",
        icon: <AiFillGithub />,
      },
      {
        name: "Linkedin",
        url: "https://www.linkedin.com/in/mz-haq",
        icon: <AiFillLinkedin />,
      },
      {
        name: "Facebook",
        url: "https://www.fb.com/a.silent.man8",
        icon: <AiFillFacebook />,
      },
      {
        name: "Instagram",
        url: "https://www.instagram.com/mozharul_huq/",
        icon: <AiOutlineInstagram />,
      },
    ],

    projects: [
      {
        name: "RobodocBD",
        pic_url: "/projects/robodoc.png",
        url: "https://www.roboDocBD.com",
        tags: ["Laravel"],
      },
      {
        name: "AmarPet",
        pic_url: "/projects/amarpet.png",
        url: "https://www.amarPet.com",
        tags: ["Laravel"],
      },
      {
        name: "Niyontron",
        pic_url: "/projects/niyontron.png",
        url: "https://www.niyontron.com",
        tags: ["Next.js"],
      },
      {
        name: "Resume Site",
        pic_url: "/projects/resume.png",
        url: "https://matr1x01.github.io/resume_site/",
        tags: ["React.js"],
      },
    ],
    skills: [
      {
        url: "/skills/laravel.svg",
        name: "Laravel",
        experience: "2 year",
      },
      {
        url: "/skills/php.png",
        name: "PHP",
        experience: "1 year",
      },
      {
        url: "/skills/react.png",
        name: "React.js",
        experience: "1 year",
      },
      {
        url: "/skills/javascript.png",
        name: "JavaScript",
        experience: "1 year",
      },
      {
        url: "/skills/python.png",
        name: "Python",
        experience: "2 year",
      },
    ],
    experience: [
      {
        duration: "Nov 2022 - Present",
        positions: ["Jr. Software Engineer", "Software Engineer Intern"],
        company: "Appnap Inc.",
      },
    ],
    education: [
      {
        duration: "Jan 2018 - Jan 2022",
        degree: "BSc in Computer Science and Engineering",
        cgpa: "3.69",
        institution: "American International University - Bangladesh (AIUB)",
      },
    ],
    testimonials: [
      {
        name: "Fida Muntaseer",
        occupation: "Tech Lead",
        company: "Appnap",
        url: "fida_muntaseer.jpg",
        text: "I have been working with Mozharul for a good time. He is very polite, hard working and disciplined. He is very passionate about his job and very serious about maintaining constant pace in his work. He is very co-operative with his peers and a great team-player. A desirable employee for any organisation.",
      },
      {
        name: "Tushar Ahmed",
        occupation: "Software Engineer",
        company: "Appnap",
        url: "/tushar_ahmed.jpeg",
        text: "It was a pleasure collaborating with Mozharul Haq on the web backend team. Mozharul's expertise in problem-solving, greatly contributed to our projects' success. As an Android developer in the tech team, I witnessed firsthand his ability to provide seamless APIs for our mobile applications. Mozharul is an invaluable asset to any team, and I highly recommend him for his exceptional skills and dedication.",
      },
      {
        name: "Shah Poran Vhuiyan",
        occupation: "SQA Engineer",
        company: "Appnap",
        url: "/shah_poran.jpeg",
        text: "Mozharul Haq is one of the best people I have ever met and is a loyal colleague, who understands complex matters. Mozharul Haq is a strong and goal-oriented team player with every problem there is a solution. Highly Recommend.",
      },
    ],
  };

  return data;
};

export default getData;
