import React from "react";

const EducationAndExperience = (props) => {
    return <>
        <div className="border-l-2 pl-16 pb-16 border-l-gray-600 flex flex-col relative group">
            <div
                className="w-3 h-3 border rounded-full bg-gray-500 group-hover:bg-[#28E98C] group-hover:border-[#28E98C] duration-500 border-gray-500 absolute top-0 -left-[.4rem]"/>
            {props.experience.map((item) => <>
                <span
                    className="text-gray-500 text-lg -top-2 absolute group-hover:text-[#28E98C]">{item.duration}</span>
                {
                    item.positions.map((position) => <>
                            <span className="text-white text-3xl mt-12 font-light">{position}</span>
                            <span className="text-gray-500 text-md mt-2">{item.company}</span>
                        </>
                    )
                }
            </>)
            }
        </div>
        <div className="border-l-2 pl-16 border-l-gray-600 flex flex-col relative group">
            {
                props.education.map((item) => <>
                    <div
                        className="w-3 h-3 border rounded-full bg-gray-500 group-hover:bg-[#28E98C] group-hover:border-[#28E98C] border-gray-500 absolute top-0 -left-[.4rem]"/>
                    <span
                        className="text-gray-500 text-lg -top-2 absolute group-hover:text-[#28E98C]">{item.duration}</span>
                    <span
                        className="text-white text-3xl mt-12 font-light">{item.degree}</span>
                    <span className="text-white text-md mt-2">CGPA - {item.cgpa}</span>
                    <span className="text-gray-500 text-md mt-2">{item.institution}</span>
                </>)
            }
        </div>
    </>;
}

export default EducationAndExperience;