import React from "react";

const ProjectTab = (props) => {
  return (
    <div className="flex flex-col my-4 group">
      <a href={props.data.url}>
        <div
          className="relative w-full h-96 bg-amber-400 rounded-3xl my-4"
          style={{
            backgroundImage: "url('" + props.data.pic_url + "')",
            backgroundSize: "contain",
          }}
        >
          <div className="absolute bottom-0 w-full h-16 flex flex-row">
            {props.data.tags.map((tag, index) => {
              return (
                <div className=" my-auto mx-4 w-32 h-12 rounded-3xl bg-white border-2 border-gray-500 group-hover:bg-gray-800 group-hover:border-gray-800 group-hover:text-white duration-500 flex justify-center items-center">
                  <span>{tag}</span>
                </div>
              );
            })}
          </div>
        </div>
      </a>
      <span className="text-2xl text-white group-hover:underline duration-500">
        {props.data.name}
      </span>
    </div>
  );
};

export default ProjectTab;
