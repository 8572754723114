import React from "react";
import HeadingWithIcon from "../Components/HeadingWithIcon";
import SideProfile from "../Components/SideProfile";
import SkillTab from "../Components/SkillTab";
import ProjectTab from "../Components/ProjectTab";
import EducationAndExperience from "../Components/EducationAndExperience";
import TestimonialsSlider from "../Components/TestimonialSlider";
import { HiOutlineHome } from "react-icons/hi2";
import { MdOutlinePersonOutline } from "react-icons/md";
import { GoCalendar, GoMail } from "react-icons/go";
import { LiaComment, LiaShapesSolid } from "react-icons/lia";
import { GrProjects } from "react-icons/gr";
import getData from "../data";
const Index = () => {
  const data = getData();
  return (
    <div className="bg-[#1F1F1F] flex flex-col lg:flex-row h-fit w-full p-1 overflow-auto font-sans">
      <div className="flex lg:w-1/2 xl:w-1/3 w-full  lg:p-4 lg:h-screen">
        <div className="lg:fixed lg:w-auto w-full">
          <SideProfile
            name={data.name}
            occupation={data.occupation}
            email={data.email}
            links={data.links}
            pictureUrl={data.pictureUrl}
            location={data.location}
            cv={"/mozharul_haq.pdf"}
          />
        </div>
      </div>
      <div className="flex flex-col lg:w-1/2 xl:w-2/3 w-full p-4 lg:px-2 lg:py-20 h-full">
        <div className="flex flex-col max-w-[55rem] w-full mt-16">
          <HeadingWithIcon icon={<HiOutlineHome />} text="INTRODUCE" />
          <span className="text-6xl xl:text-8xl text-white my-6 mt-12">
            Say Hi from{" "}
            <span className="text-[#28E98C]">{data.name.split(" ")[0]}</span>,{" "}
            {data.occupation}
          </span>
          <span className="text-gray-500 my-6 max-w-[30rem]">
            {data.introduction}
          </span>
        </div>
        <div className="flex flex-col max-w-[55rem] w-full mt-16">
          <HeadingWithIcon icon={<MdOutlinePersonOutline />} text="ABOUT" />
          <span className="text-4xl lg:text-6xl text-white my-6 mt-12">
            Every great work begins with a even{" "}
            <span className="text-[#28E98C]">better story</span>
          </span>
          <span className="text-gray-500 my-6 max-w-[30rem]">{data.about}</span>
        </div>
        <div className="flex flex-col max-w-[55rem] w-full mt-16">
          <HeadingWithIcon icon={<GoCalendar />} text="RESUME" />
          <span className="text-4xl lg:text-6xl text-white my-6 mt-12">
            Education & <span className="text-[#28E98C]">Experience</span>
          </span>

          <EducationAndExperience
            experience={data.experience}
            education={data.education}
          />
        </div>
        <div className="flex flex-col max-w-[55rem]  w-full mt-16">
          <HeadingWithIcon icon={<LiaShapesSolid />} text="MY SKILLS" />
          <span className="text-4xl lg:text-6xl text-white my-6 mt-12">
            My <span className="text-[#28E98C]">Advantages</span>
          </span>
          <div className="flex flex-wrap">
            {data.skills.map((item) => (
              <SkillTab data={item} />
            ))}
          </div>
        </div>
        <div className="flex flex-col max-w-[55rem] w-full mt-16">
          <HeadingWithIcon icon={<GrProjects />} text="PORTFOLIO" />
          <span className="text-4xl lg:text-6xl text-white my-6 mt-12">
            Featured <span className="text-[#28E98C]">Projects</span>
          </span>
          <div className="flex flex-col">
            {data.projects.map((item) => (
              <ProjectTab data={item} />
            ))}
          </div>
        </div>
        <div className="flex flex-col max-w-[55rem]  w-full mt-16">
          <HeadingWithIcon icon={<LiaComment />} text="TESTIMONIAL" />
          <span className="text-4xl lg:text-6xl text-white my-6 mt-12">
            Trusted by <span className="text-[#28E98C]">Colleague</span> and{" "}
            <span className="text-[#28E98C]">Client</span>
          </span>
          <div className="flex flex-wrap">
            <TestimonialsSlider items={data.testimonials} />
          </div>
        </div>
        <div className="flex flex-col max-w-[55rem] w-full mt-16">
          <HeadingWithIcon icon={<GoMail />} text="CONTACT" />
          <span className="text-4xl lg:text-6xl text-white my-6 mt-12">
            Let's Work <span className="text-[#28E98C]">Together! </span>
          </span>
          <div className="flex flex-col">
            <span className="lg:text-3xl text-xl text-white my-8">
              {data.email}
            </span>
            <form>
              <div className="flex xl:flex-row flex-col w-full h-fit">
                <div className="flex flex-col w-full mr-6">
                  <span className="text-white my-3 font-light">Email</span>
                  <input
                    className="bg-transparent text-white text-xl focus:outline-none"
                    placeholder="Your Email"
                    type="text"
                  />
                </div>
                <div className="flex flex-col w-full mr-6">
                  <span className="text-white my-3 font-light">Phone</span>
                  <input
                    className="bg-transparent text-white text-xl focus:outline-none"
                    placeholder="Your Phone Number"
                    type="text"
                  />
                </div>
              </div>
              <div className="flex flex-col w-full mr-6">
                <span className="text-white my-3 font-light">Name</span>
                <input
                  className="bg-transparent text-white text-xl focus:outline-none"
                  placeholder="Your Name"
                  type="text"
                />
              </div>
              <div className="flex flex-col w-full mr-6">
                <span className="text-white my-3 font-light">Message</span>
                <textarea
                  rows="4"
                  className="bg-transparent text-white text-xl focus:outline-none resize-none"
                  placeholder="Your Message"
                />
              </div>
              <div className="select-none max-w-[20rem] w-full h-[3rem] rounded-3xl bg-[#28E98C] border-2 border-[#28E98C] mt-2 mb-6 flex justify-center items-center font-light text-xl text-black hover:text-white hover:bg-transparent transition duration-300 ease-in-out">
                <span>Send Message</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
