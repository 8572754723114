import React, { useState } from "react";
import { GrNext, GrPrevious } from "react-icons/gr";

const TestimonialSlider = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex(
      (nextIndex) => (nextIndex + 1) % items.length
    );
  };

  return (
    <div className="relative w-full">
      <div className="overflow-hidden">
        <div
          className="flex transition-transform duration-300 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {items.map((testimonial, index) => (
            <div key={index} className="w-full flex-shrink-0">
              <div className="border rounded-3xl p-16 m-2">
                <div className="flex flex-row h-auto w-auto">
                  <div className="w-12 h-12">
                    <img
                      src={testimonial.url}
                      alt=""
                      className="w-12 h-12 rounded-full"
                    />
                  </div>
                  <div className="flex flex-col my-auto h-full">
                    <span className="text-white text-xl font-light mx-4">
                      {testimonial.name}
                    </span>
                    <span className="text-gray-500 text-sm font-light mx-4">
                      {testimonial.occupation} of{" "}
                      <span className="text-[#28E98C]">
                        {testimonial.company}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="text-white mt-8">
                  <span className="lg:text-3xl text-xl">"</span>
                  <span className="lg:text-2xl text-xl">
                    {testimonial.text}
                  </span>
                  <span className="lg:text-3xl text-xl">"</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-row m-8">
        <div
          className="border rounded-full w-[3rem] h-[3rem] text-white text-2xl flex items-center  cursor-pointer"
          onClick={handlePrev}
        >
          <GrPrevious className="m-auto" />
        </div>
        <div className="my-auto mx-4">
          <span className="text-white">{currentIndex + 1}</span>
          <span className="text-gray-500">/{items.length}</span>
        </div>
        <div
          className="border rounded-full w-[3rem] h-[3rem] text-white text-2xl flex items-center cursor-pointer"
          onClick={handleNext}
        >
          <GrNext className="m-auto" />
        </div>
      </div>
    </div>
  );
};

export default TestimonialSlider;
