import React from "react";

const HeadingWithIcon = ({ icon, text }) => {
  return (
    <div className="border rounded-full border-[#565656] px-4 py-2 flex items-center text-white w-fit select-none">
      <span className="mr-2">{icon}</span>
      <span className="font-normal text-sm">{text}</span>
    </div>
  );
};

export default HeadingWithIcon;
